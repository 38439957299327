@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.container {
  width: 100%;
  padding-top: 25px;
}

.container_inner {
  max-width: 350px;
  margin: 0 auto;
  @include mix.min-media(var.$screen-sm) {
    max-width: 480px;
  }

  @include mix.min-media(var.$screen-md) {
    max-width: 710px;
  }

  @include mix.min-media(var.$screen-slg) {
    max-width: 880px;
  }

  @include mix.min-media(var.$screen-mlg) {
    max-width: 1000px;
  }
}

.googleMap_container {
  clear: both;
  width: 100%;
}
