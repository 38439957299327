@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as func;

.footer {
  background-color: #30251b;
}

.footer_inner {
  padding: 30px 20px 0;

  @include mix.min-media(var.$screen-sm) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.footer_group_primary {
  display: flex;
  flex-flow: column wrap;
  justify-content: start;

  @include mix.min-media(var.$screen-sm) {
    flex-flow: row wrap;
    width: 50%;
  }

  @include mix.min-media(var.$screen-md) {
    width: 40%;
  }

  @include mix.min-media(var.$screen-slg) {
    width: 46%;
  }
}

.footer_logo {
  width: 100%;
  margin-bottom: 24px;

  @include mix.min-media(var.$screen-sm) {
    width: 55%;
    margin-right: 20px;
  }
  @include mix.min-media(var.$screen-md) {
    width: 40%;
  }
  @include mix.min-media(var.$screen-slg){
    width: 30%;
  }
}

.footer_img {
  width: 100%;

  img {
    max-width: 108px;
  }

  @include mix.min-media(var.$screen-sm) {
    width: 100%;
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}

.footer_adress {
  margin-bottom: 32px;
}

.footer_adress_body {
  @include mix.min-media(var.$screen-sm) {
    display: none;
  }
}

.footer_adress_body_pc {
  display: none;
  @include mix.min-media(var.$screen-sm) {
    display: block;
  }
}

.adress_item {
  font-size: 14px;
  color: #fff;
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

// businessHours
// ============================================================
.businessHours {
  margin-top: 6px;
  margin-left: 14px;
}

// fnav
.footer_group_secondary {
  display: none;

  @include mix.min-media(var.$screen-md) {
    display: block;
    align-self: center;
  }
}

.fnav_body {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  @include mix.max-media(var.$screen-mlg) {
    display: none;
  }
}

.fnav_item {
  border-right: 1px solid #fff;
  padding: 0 15px;

  &:last-child {
    border-right: none;
  }
}

// copyright部分
// ============================================================

.copyright {
  text-align: center;
  background-image: url("../img/common/copyright_bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  padding: 10px 0 11px;
}
