@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as func;

.offcanvasTrigger {
  @include mix.min-media(var.$screen-mlg) {
    display: none;
  }
}

// ------------------------------------------------------------
// offcanvasMenu_body
// ------------------------------------------------------------

.offcanvasMenu_body {
  padding: 0;
  margin: 0;
  list-style: none;
}

.offcanvasMenu_item {
  display: block;
  width: 100%;
  position: relative;
  border-bottom: solid 1px #ccc;
  font-size: 15px;

  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
    //
    display: block;
    content: "";
    height: 100%;
    width: 5px;
    background: #000;
  }

  a {
    display: block;
    padding: 15px;
    line-height: 1.1;
  }
}

.offcanvasMenu_item_color01:before {
  background: #f8aa36;
}
.offcanvasMenu_item_color02:before {
  background: #db5169;
}
.offcanvasMenu_item_color03:before {
  background: #368459;
}
.offcanvasMenu_item_color04:before {
  background: #2169ab;
}
.offcanvasMenu_item_color05:before {
  background: #cc4242;
}
.offcanvasMenu_item_color06:before {
  background: #f3ca3b;
}

// offcanvasMenu_secondary
// -------------------

.offcanvasMenu_secondary {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
}

.offcanvasMenu_secondary_item {
  width: 50%;
  border-bottom: solid 1px #ccc;
  font-size: 13px;
  line-height: 1.4;

  &:nth-child(even) {
    border-left: solid 1px #ccc;
  }

  > a {
    display: block;
    width: 100%;
    padding: 10px 8px;
  }
}

// offcanvas_footer{

.offcanvas_footer_img{
  width: func.px2per(108,235);

  @include mix.min-media(var.$screen-sm){
    width: func.px2per(143,465);
  }

  img{
    width: 100%;
    max-width: 100%;

  }
}


// ============================================================
// offcanvasTriggerUnit
// ============================================================

$trigger_color: #fff;
$trigger_color_active: #fff;
$triggerW: 31px;
$triggerH: 23px;
$triggerWSP: 21px;
$triggerHSP: 15px;

.offcanvasTriggerUnit {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: var.$zindex_offcanvas_trigger;
  //
  display: none;
  @include mix.max-media(var.$screen-mlg) {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 53%;
    right: 0;
    transform: translate(-50%, -50%);
  }

  @include mix.max-media(var.$screen-sm) {
    right: -2%;
  }
}

.offcanvasTrigger,
.offcanvasTrigger span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
}
.offcanvasTrigger {
  position: relative;
  width: $triggerW;
  height: $triggerH;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;

  // @include mix.max-media(var.$screen-sm){
  //   width: $triggerWSP;
  //   height: $triggerHSP;
  // }
}
.offcanvasTrigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $trigger_color;
}
.offcanvasTrigger span:nth-of-type(1) {
  top: 0;
}
.offcanvasTrigger span:nth-of-type(2) {
  top: $triggerW / 2 - 5.5px;

  @include mix.max-media(var.$screen-sm) {
    top: 45%;
  }
}
.offcanvasTrigger span:nth-of-type(3) {
  bottom: 0;
}

.js_isOffcanvasActive .offcanvasTrigger span:nth-of-type(1) {
  transform: translateY(11px) rotate(-45deg);
  background-color: $trigger_color_active;

  // @include mix.max-media(var.$screen-sm){
  //   transform: translateY(7px) rotate(-45deg);
  // }
}
.js_isOffcanvasActive .offcanvasTrigger span:nth-of-type(2) {
  left: 50%;
  opacity: 0;
  animation: active-menu05-bar02 0.8s forwards;
}
@keyframes active-menu05-bar02 {
  100% {
    height: 0;
  }
}
.js_isOffcanvasActive .offcanvasTrigger span:nth-of-type(3) {
  transform: translateY(-10px) rotate(45deg);
  background-color: $trigger_color_active;

  // @include mix.max-media(var.$screen-sm){
  //   transform: translateY(-7px) rotate(45deg);
  // }
}

//
// -------------------

.offcanvasTrigger_label {
  font-size: 10px;
  text-align: center;
  margin-top: 5px;
  font-family: var.$brand-font-serif;
  color: $trigger_color;

  // @include mix.max-media(var.$screen-sm){
  //   transform: scale(0.75);
  //   margin-top: 3px;
  // }
}

.js_isOffcanvasActive .offcanvasTrigger_label {
  color: $trigger_color_active;
}
