@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.grid_group {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  //
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 40px;
  @include mix.min-media(var.$screen-md) {
    margin-right: 0;
    margin-left: 0;
  }
}

.m_index_grid_group{
  margin-bottom: 80px;
  @include mix.min-media(var.$screen-md) {
    margin-bottom: 73px;
  }
}

// SP画面時
// ============================================================

.grid_col_06 {
  row-gap: 10px;
  .grid_item {
    width: calc((100%) / 2);
    padding-right: 5px;
    padding-left: 5px;

    @include mix.min-media(var.$screen-md) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// PC時表示
// ============================================================

.grid_col_03_lg {
  @include mix.min-media(var.$screen-md) {
    column-gap: 20px;
    row-gap: 20px;
    .grid_item {
      width: calc((100% - 60px) / 4);
    }
  }
}

.grid_col_04_lg {
  @include mix.min-media(var.$screen-md) {
    column-gap: 20px;
    row-gap: 30px;

    .grid_item {
      width: calc((100% - 60px) / 4);

      &:first-child {
        width: calc((100% - 20px) / 2);
      }
    }
  }
}

// 汚染対策
// ============================================================

.category_index {
  .grid_item {
    margin-bottom: 0;
  }
}
