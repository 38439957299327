@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as func;

.scrollDown01 {
  /*描画位置※位置は適宜調整してください*/
  position: fixed;
  right: 50px;
  bottom: 21%;
  /*全体の高さ*/
  height: 50px;
  z-index: 3;

  @include mix.max-media(var.$screen-mlg) {
    bottom: 41%;
  }

  @include mix.max-media(var.$screen-slg) {
    bottom: 5%;
  }

  @include mix.max-media(var.$screen-sm) {
    right: 15px;
    display: none;
  }

  &:before {
    content: "";
    /*描画位置*/
    position: absolute;
    top: -10%;
    /*線の形状*/
    width: 1px;
    height: 30px;
    background: #fff;
    /*線の動き1.4秒かけて動く。永遠にループ*/
    animation: pathmove 1.4s ease-in-out infinite;
    opacity: 0;
    margin-top: 10px;
  }
}

.scrollDown01_body {
  /*描画位置*/
  position: absolute;
  left: -5px;
  bottom: 5px;
  /*テキストの形状*/
  color: #fff;
  font-size: 0.7rem;
  letter-spacing: 0.05em;
  transform: rotate(90deg);
}

/*高さ・位置・透過が変化して線が上から下に動く*/
@keyframes pathmove {
  0% {
    height: 0;
    top: 0;
    opacity: 0;
  }
  30% {
    height: 30px;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 50px;
    opacity: 0;
  }
}
