@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.gnav {
  @include mix.min-media(var.$screen-mlg) {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }
}

.header_logo.gnav_header_logo {
  margin-bottom: 36px;
  width: function.px2per(108, 233);

  @include mix.min-media(var.$screen-mlg) {
    display: none;
  }
}

.gnav_menu_title {
  color: #fff;
  border-color: #fff;
  padding-bottom: 15px;

  @include mix.min-media(var.$screen-mlg) {
    display: none;
  }
}

.gnav_body,
.gnav_body_slave {
  color: #fff;
  padding-left: 20px;
  @include mix.min-media(var.$screen-mlg) {
    padding-left: 0;
  }
}

.gnav_body {
  margin-bottom: 84px;

  @include mix.min-media(var.$screen-mlg) {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;

    .gnav_item.hidden_sm:last-of-type {
      margin-right: 0;
    }
  }
}

.gnav_body_slave {
  font-weight: normal;

  @include mix.min-media(var.$screen-mlg) {
    display: none;
  }
}

.gnav_item {
  font-family: var.$brand-font-serif;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }


  @include mix.min-media(var.$screen-mlg) {
    margin-bottom: 0;
    margin-right: 12px;
  }

  @include mix.min-media(1100px){
    margin-right: 20px;
  }



  a {
    font-weight: normal;
  }
}

.gnav_slave_item {
  margin-bottom: 20px;
}

.gnav_item,
.gnav_slave_item {
  position: relative;

  &:before {
    content: "・";
    position: absolute;
    left: -20px;

    @include mix.min-media(var.$screen-mlg) {
      display: none;
    }
  }
}

// gnav_footer
// ============================================================

.gnav_footer {
  @include mix.min-media(var.$screen-mlg) {
    display: none;
  }
}
