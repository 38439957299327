@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
// README
// ============================================================
//
// HTML SAMPLE
//
// <div id="pagetop" class="pagetop" data-offsetbottom="20">
//   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.13 13.27"><polygon class="pagetop_icon_svg" points="11.59 0 23.13 11.55 21.44 13.23 11.59 3.38 1.69 13.27 0 11.59 11.59 0"/></svg>
// </div>
//
// # 備考
// data-offsetbottomにはボタン表示時のbottomの値を設定する必要がある
// スクロールの際、ある要素を子した場合、対象の要素に固定する場合（js_isFixed）は対象の要素の直下の要素としてHTML SAMPLEのコードを追加させる必要がある
//
// ============================================================

$pagetopWidth: 44px;
$pagetopHeight: 44px;
$pagetopBgColor: var.$brand-main;
$pagetopHoverBgColor: var.$brand-main-darker;

// 構造
// -------------------
.pagetop {
  position: fixed;
  right: 15px;
  bottom: 20px;
  transform: translate(0%, 200%);
}

.pagetop.js_isFixed {
  transform: translate(0%, -100%);
  bottom: 0;
}

.pagetop.js_isHold {
  z-index: 9999;
  position: absolute;
  top: 0;
  bottom: auto;
  transform: translate(0%, -50%);
}

//見た目
// -------------------
.pagetop {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  //
  width: $pagetopWidth;
  height: $pagetopHeight;
  background: $pagetopBgColor;
  border-radius: 200px;
  @include mix.transitionDelay();
  &:hover {
    background: $pagetopHoverBgColor;
    cursor: pointer;
  }
}

.pagetop svg {
  width: 50%;
}

.pagetop_icon_svg {
  fill: #fff;
}
