@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// sg_container
// ------------------------------------------------------------

.sg_container_wrapper {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 100px 100px 0 100px;
  @include mix.max-media(var.$screen-slg) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.sg_container {
  width: calc(100% - 350px);
  &_inner {
    margin: 0 auto;
    > *:first-child {
      margin-top: 0 !important;
    }
  }
  @include mix.max-media(var.$screen-slg) {
    width: 100%;
  }
}

.sg_side_container {
  width: 280px;
  min-height: 500px;
  border-right: 1px solid #ccc;
  @include mix.max-media(var.$screen-slg) {
    display: none;
  }
}

// sg_side_nav
// ------------------------------------------------------------

.sg_nav_body {
  display: flex;
  flex-flow: row wrap;

  align-items: center;
  //
  list-style: none;
  margin: 0 100px 0 auto;
  padding: 0;

  li {
    display: block;
    width: 100%;
    padding: 5px 10px;

    a {
      display: block;
      padding: 0.5em 0;
      &:active,
      &:link,
      &:visited,
      &:focus,
      &:hover {
        color: #333;
        text-decoration: none;
      }
    }
  }
}

// sg_header
// ------------------------------------------------------------

.sg_header {
  background: #eee;
  height: 80px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;

  &_inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
    width: 100%;
    max-width: 100%;
    @include mix.max-media(var.$screen-slg) {
      padding: 0 10px;
    }
  }
}

// sg_nav
// ------------------------------------------------------------

.sg_nav {
  display: flex;
  flex-flow: row wrap;

  align-items: center;
  //
  list-style: none;
  margin-left: auto;
  padding: 0;

  li {
    a {
      display: block;
      padding: 0.25em 1em;
    }
  }

  @include mix.max-media(var.$screen-slg) {
    margin-left: 0;
  }
}

// sg_mv
// ------------------------------------------------------------

.sg_mv {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background: #ccc;
}

.sg_mv_inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.sg_mv_title {
  width: 100%;
  margin: 0 auto 0.5em;
  font-size: 1.5em;
  text-align: center;
  line-height: 1.4;
}

.sg_mv_lead {
  width: 100%;
  text-align: center;
  font-size: 0.8em;
}

// sg_footer
// ------------------------------------------------------------

.sg_footer {
  position: relative;
  background: #eee;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.sg_copy {
  font-size: 11px;
  line-height: 1;
  padding: 1em;
}

// ============================================================
// パーツ
// ============================================================

.sg_heading01 {
  font-size: 2.5em;
  margin: 2em 0 2em;
}

.sg_heading02 {
  font-size: 2.2em;
  margin: 2em 0 1em;
  padding-bottom: 0.5em;
  border-bottom: solid 5px #ccc;
}

.sg_heading04 {
  font-size: 1.5em;
  margin: 2em 0 1em;
}

// btn
// ------------------------------------------------------------

.sg_btn01 {
  @include mix.resetBtnStyle();
  display: inline-block;
  border: solid 1px #0028b7;
  padding: 0.5em 5em 0.6em;
  border-radius: 5em;
  font-size: 1em;
  color: #0028b7;
  background-color: #ffffff;
}

// code
// ------------------------------------------------------------

.sg_code_inline {
  display: inline-block;
  border: solid 1px #ec007a;
  background: #ffecf8;
  color: #ec007a;
  border-radius: 5px;
  font-size: 0.8em;
  padding: 0.27em 0.5em 0.2em;
  line-height: 1.1;
}

.sg_path_inline {
  display: inline-block;
  border: solid 1px #ccc;
  background: #f6f6f6;
  color: #333;
  border-radius: 5px;
  margin-left: 0.5em;
  font-size: 0.8em;
  padding: 0.27em 0.5em 0.2em;
  line-height: 1.1;
}

// units
// ------------------------------------------------------------

.sg_section_unit {
  margin-bottom: 10em;
}

.sg_demo_unit {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  //
  position: relative;
  border-radius: 10px;
  border: solid 1px #ccc;
  padding: 20px;
  margin-bottom: 2em;
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    content: "DEMO";
    background: #fff;
    padding: 0.3em 0.5em;
    font-size: 10px;
    line-height: 1.1;
    border-left: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    border-radius: 0 10px 0 0;
  }
  &_inner {
  }
}

.sg_textUnit {
  margin-bottom: 2em;
  > p {
    margin-bottom: 1em;
    line-height: 1.6em;
  }
  > p:last-child {
    margin-bottom: 0;
  }
}

// ============================================================
