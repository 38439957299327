@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.introduction_textUnit {
  width: 490px;
  margin-left: auto;
  margin-right: 20px;

  @include mix.max-media(var.$screen-slg) {
    width: 350px;
    margin-left: 0;
  }

  @include mix.max-media(var.$screen-md) {
    width: 100%;
    margin-right: 0;
    padding: 0 30px;
  }
}

.space_textUnit,
.payment_textUnit,
.access_textUnit {
  width: 50%;
  @include mix.max-media(var.$screen-md) {
    width: 100%;
  }
}

.space_textUnit {
  @include mix.max-media(var.$screen-md) {
    padding: 0 30px;
  }
}
