@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.header {
  z-index: var.$zindex-header;
  background-image: url("../img/common/header_bg.jpg");
  background-size: auto;
  background-position: center;

  @include mix.min-media(var.$screen-sm) {
    display: block;
    background-image: none;
    background-color: #30251b;
  }
}

.header_inner {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 11px 0 16px;

  @include mix.min-media(var.$screen-mlg) {
    flex-flow: row wrap;
    padding: 0px 0px 0px 62px;
  }
}

// header_logo
// ============================================================

.header_logo {
  width: function.px2per(170, 390);
  @include mix.min-media(var.$screen-mlg) {
    width: function.px2per(115, 1009);
  }
  
}

.header_logo_img {
  background-image: url("../img/common/header_logo.svg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: left center;
  padding-top: 60px;
}

// header_group
// ============================================================

.header_group {
  display: flex;
  flex-flow: column wrap;
  justify-content: left;
  align-items: center;
}

// page_header
// ============================================================
.page_header {
  margin-bottom: 30px;
}

.header_phone,.header_reserved {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  margin-top: 3px;
  margin-right: 61px;

  @include mix.min-media(var.$screen-mlg) {
    display: none;
  }
}

.header_reserved {
  margin-right: -12%;

  @include mix.min-media(var.$screen-sm){
    margin-right: -20%;
  }

  @include mix.min-media(var.$screen-md){
    margin-right: -30%;
  }

  @include mix.min-media(750px){
    margin-right: -35%;
  }

  @include mix.min-media(var.$screen-slg){
    margin-right: -40%!important;
  }


}

.header_phone_label ,.header_reserved_label{
  font-size: 10px;
  font-family: var.$brand-font-serif;
  color: #fff;
}

.phone_icon {
  display: block;
  position: relative;
  padding-top: 26px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    //
    background-image: url("../img/common/header_phone_icon.svg");
    background-size: auto;
    background-position: top center;
    background-repeat: no-repeat;
  }

  @include mix.min-media(var.$screen-mlg) {
    width: 20%;
  }
}

.reserved_icon{
  display: block;
  position: relative;
  padding-top: 26px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    //
    background-image: url("../img/common/header_reserved_icon.svg");
    background-size: auto;
    background-position: top center;
    background-repeat: no-repeat;
  }

  @include mix.min-media(var.$screen-mlg) {
    width: 20%;
  }
}

// header_phone_sp
// ============================================================

.header_phone_sp {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  //
  width: 100%;
  margin-right: 61px;
  background-color: #846b17;
  padding: 10px 0 10px 0;

  .phone_icon {
    position: absolute;
    left: 13px;
    width: function.px2per(40, 203);
  }
}

.header_phone_sp a {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

//phone_cta

.phone_cta p {
  color: #fff;
  font-weight: normal;
  font-size: 10px;
  text-align: center;

  &:nth-child(2) {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.1;
  }
}
