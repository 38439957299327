@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
// topページ
// ============================================================

// section_container
// ============================================================
.top_section_container {
  background: #fff;
  width: 100%;
}

.section_inner {
  width: 100%;
  margin: 0 13.396779%;
}

// top_introduction
// ============================================================

.top_introduction_container {
  @include mix.clearfix;
}

.top_introduction_row {
  width: 50%;
  @include mix.max-media(var.$screen-md) {
    width: 100%;
  }

  &.is_leftside {
    float: left;
    @include mix.max-media(var.$screen-md) {
      float: none;
    }
  }
  &.is_rightside {
    float: right;
    @include mix.max-media(var.$screen-md) {
      float: none;
    }
  }
  &.is_media {
    figure {
      width: 100%;

      @include mix.max-media(var.$screen-slg) {
        width: 90%;
        margin-left: auto;
      }
      @include mix.max-media(var.$screen-md) {
        width: 100%;
      }

      img {
        width: 100%;
        max-width: 100%;
        vertical-align: bottom;
      }
    }
  }

  &.is_suspended {
    margin-top: -170px;
    margin-left: -70px;

    @include mix.max-media(var.$screen-mlg) {
      margin-top: -130px;
    }

    @include mix.max-media(var.$screen-slg) {
      margin-top: -50px;
    }

    @include mix.max-media(var.$screen-md) {
      margin-top: 0;
      margin-left: 0;
    }
  }

  &.is_leftSuspend {
    margin-left: -20px;

    @include mix.max-media(var.$screen-mlg) {
      margin-left: -10px;
    }
    @include mix.max-media(var.$screen-slg) {
      margin-left: 0;
    }
  }
}

.top_introduction_row_inner {
  width: 490px;

  @include mix.max-media(var.$screen-slg){
    width: 405px;
  }

  @include mix.max-media(var.$screen-sm){
    width: 100%;
  }

  .is_leftside & {
    margin-left: auto;
  }
  .is_rightside & {
    margin-right: auto;
  }

 
}

.top_introduction_title {
  margin-right: 20px;
  @include mix.max-media(var.$screen-sm) {
    margin-right: 0;
    padding-left: 30px;
  }
}

.introduction_img {
  width: 50%;

  @include mix.max-media(var.$screen-md) {
    width: 100%;
  }
}

.title_lead_en {
  font-size: 15px;
  font-weight: bold;
  color: #968500;
}

// top_space
// ============================================================

.top_space_container {
  width: 100%;
  padding-bottom: 120px;
}

.top_space_inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
  max-width: 918px;
  margin-left: 11.775599%;
  margin-right: auto;

  @include mix.max-media(var.$screen-mlg) {
    margin-left: -0.224401%;
  }
}

.top_space_title {
  width: calc(50% - 10px);
  margin-right: 10px;
  padding-left: 8%;

  @include mix.max-media(var.$screen-md) {
    width: 100%;
    margin-right: 0;
    margin-left: 30px;
    margin-bottom: 36px;
  }

  @include mix.max-media(var.$screen-sm){
    margin-left: 0;
  }
}

// top_menu
// ============================================================

.top_section_menu {
  position: relative;
  width: 100%;
  background: #fff;
  padding: 120px 0;

  @include mix.max-media(var.$screen-md) {
    padding: 60px 0;
  }

  &:before {
    content: url("../img/top/menu_point01.png");
    position: absolute;
    left: 0;
    bottom: 5%;
    @include mix.max-media(var.$screen-md) {
      display: none;
    }
  }

  &:after {
    content: url("../img/top/menu_point02.png");
    position: absolute;
    right: 0;
    top: 5%;
    @include mix.max-media(var.$screen-md) {
      display: none;
    }
  }
}

.menu_inner {
  @include mix.clearfix;
  @include mix.max-media(var.$screen-md) {
    padding: 0 30px;
  }
}

.top_menu_title {
  float: right;
  width: 50%;
  margin-left: 40px;

  @include mix.max-media(var.$screen-md) {
    float: none;
    width: 100%;
    margin-left: 0;
  }
}

.menu_content {
  width: 50%;
  margin-left: auto;

  @include mix.max-media(var.$screen-md) {
    width: 100%;
  }
}

.menu_img {
  width: calc(50% - 40px);
  float: left;
  margin-bottom: 120px;

  @include mix.max-media(var.$screen-sm) {
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    max-width: 100%;
  }

  @include mix.max-media(var.$screen-md) {
    width: 100%;
    float: none;
  }
}

// top_payment

.top_payment {
  clear: both;
}

.top_payment_title {
  float: left;
  width: 50%;

  @include mix.max-media(var.$screen-md) {
    float: none;
    width: 100%;
  }
}

.payment_img {
  float: right;
  width: 50%;

  img {
    width: 100%;
    max-width: 100%;
  }

  @include mix.max-media(var.$screen-md) {
    float: none;
    width: 100%;
  }
}

// top_section_access
// ============================================================

.top_section_access {
  width: 100%;
  background: #f7f7f7;
  padding: 60px 0 40px;

  @include mix.max-media(var.$screen-md) {
    padding-top: 60px;
  }
}

.access_inner {
  @include mix.clearfix;
  @include mix.max-media(var.$screen-md) {
    padding: 0 30px;
  }
}

.top_access_title {
  float: left;
  width: calc(50% - 40px);
  margin-right: 40px;

  @include mix.max-media(var.$screen-md) {
    float: none;
    width: 100%;
    margin-right: 0;
  }
}

.access_img {
  float: right;
  width: 50%;

  @include mix.max-media(var.$screen-md) {
    float: none;
    width: 100%;
  }

  img {
    width: 100%;
    max-width: 100%;
  }
}

.sns_bnr_group {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  column-gap: 20px;

  @include mix.max-media(var.$screen-mlg) {
    column-gap: 10px;
  }

  @include mix.max-media(var.$screen-md) {
    display: block;
    width: 100%;
  }
}

.sns_bnr {
  width: function.px2per(320, 1000);

  @include mix.max-media(var.$screen-md) {
    width: 100%;
  }

  a {
    @include mix.setLinkHoverFadeAnime;
    display: block;
    height: 100%;
  }

  img {
    width: 100%;
    max-width: 100%;
  }
}

// top_section_cta
// ============================================================
.top_section_cta {
  background-color: #fff;
  padding-bottom: 60px;

  @include mix.max-media(var.$screen-sm) {
    padding: 0 30px 60px;
  }
}

.cta_inner {
  background-color: #1ec755;
  border-radius: 9px;
  padding: 30px 0 40px;

  @include mix.max-media(var.$screen-sm) {
    padding: 30px 20px 40px;
  }
}

.cta_img {
  width: function.px2per(680, 1000);
  text-align: center;
  margin: 0 auto;

  @include mix.max-media(var.$screen-sm) {
    display: none;
  }

  img {
    width: 100%;
    max-width: 100%;
  }
}

//SP時で画像を出し分けるために_soクラスを追加

.cta_img_sp {
  display: none;
  @include mix.max-media(var.$screen-sm) {
    display: block;
    width: 100%;
  }

  img {
    width: 100%;
    max-width: 100%;
  }
}

.cta_benefit {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  column-gap: 40px;

  @include mix.max-media(var.$screen-sm) {
    flex-flow: column;
    row-gap: 16px;
  }
}

.benefit_item {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.benefit_title {
  border: 2px solid #fff;
  font-size: 13px;
  color: #fff;
  text-align: center;
  //
  max-width: 76px;
  padding: 4px 19px;
}

.benefit_sentence {
  text-align: center;

  p {
    font-size: 18px;
    color: #fff;
    font-family: "Noto Sans";
    font-weight: bold;
    line-height: 1.4;
  }
}

.cta_precaution {
  p {
    font-family: "Noto Sans";
    text-align: center;
    color: #fff;
  }
}

// ============================================================
// 以下、メニュー一覧ページ   /menu/index.html
// ============================================================

// menu_overview
// ============================================================
.menu_overview {
  margin: 10px 0 40px;

  @include mix.min-media(var.$screen-slg) {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    column-gap: 20px;
    margin: 50px 0;
  }
}

.overview_discription {
  display: none;

  @include mix.min-media(var.$screen-slg) {
    display: flex;
    flex-flow: column;
    row-gap: 36px;
  }
}

.grandMenu_img {
  margin-bottom: 10px;

  img {
    width: 100%;
  }

  @include mix.min-media(var.$screen-slg) {
    width: calc((100% - 20px) / 2);
    margin-bottom: 0;
  }
}

.overview_title {
  font-family: var.$brand-font-serif;
}

.overview_textUnit {
  font-weight: bold;
}

.overview_cta {
  @include mix.min-media(var.$screen-slg) {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    row-gap: 30px;
    width: calc((100% - 20px) / 2);
  }
}

.cta_btn_group {
  @include mix.min-media(var.$screen-slg) {
    width: 50%;
  }
}

.grandMenu_btn {
  padding: 7px 0 6px;
}
