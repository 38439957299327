@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.btn_group_center {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.btn_group_left {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  text-align: left;
}

.menu_btn_group {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  @include mix.max-media(var.$screen-mlg) {
    flex-flow: column;
    align-items: center;
  }
}

// btn01
// ============================================================

.btn01 {
  width: function.px2per(170, 350);
  background-color: #d5d5d5;
  border-radius: 3px;
  box-shadow: 1px 2px 0px #000;
  margin-right: 10px;
  margin-bottom: 10px;

  &:nth-of-type(even) {
    margin-right: 0;
  }

  @include mix.min-media(var.$screen-sm) {
    width: function.px2per(170, 710);
    &:nth-of-type(even) {
      margin-right: 10px;
    }
    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }
}

.btn01 p {
  font-family: "Noto Serif";
  font-weight: bold;
  padding: 12px 0 13px;

  @include mix.min-media(var.$screen-sm) {
    padding: 3px 0 5px;
  }
}

// btn02
// ============================================================

.btn02 {
  width: 100%;
  background-color: #584737;
  border-radius: 3px;
  box-shadow: 1px 2px 0 #000;
}

.btn02 p {
  color: #fff;
}

.btn02_2 {
  min-height: 41px;
  overflow: hidden;

  @include mix.min-media(var.$screen-sm) {
    max-height: none;
  }
  a {
    display: flex;
    flex-flow: row wrap;
  }

  p {
    display: block;
    width: 75%;
    align-self: center;
    padding-left: 10px;
    white-space: nowrap;
  }

  .img_fruid_unit {
    width: 25%;
    height: 100%;
    border-radius: 3px 0 0 3px;
    overflow: hidden;
    @include mix.min-media(var.$screen-sm) {
      max-height: 41px;
    }
  }

  @include mix.min-media(var.$screen-lg) {
    max-height: 41px;
  }
}

.btn02.btn02_2 {
  margin-bottom: none;
}

// btn03
// ============================================================

.btn03 {
  border-radius: 2.2em;
  box-shadow: 1px 2px 0 #000;
  width: 100%;
  padding: 4px 0 5px;
}

// btn04
// ============================================================

.btn04 {
  border: 1px solid #333;
  border-radius: 2em;
  background-color: transparent;

  a {
    padding: 0.7em 1.33em 0.8em 1.33em;
  }

  &:hover {
    @include mix.setLinkHoverFadeAnime;
    background-color: #333;
    border: 1px solid #fff;
  }
}

.top_section_menu_btn {
  width: function.px2per(235, 500);

  @include mix.max-media(var.$screen-mlg) {
    width: function.px2per(235, 355);
  }

  a {
    font-family: var.$brand-font-serif;
    display: block;
    height: 100%;
    color: #333;
    line-height: 1.4;
  }

  a:hover {
    color: #fff;
  }

  @include mix.max-media(var.$screen-sm) {
    width: function.px2per(235, 290);
    margin: 0 auto;
  }
}

.cta_btn {
  width: function.px2per(299, 1000);
  border: 1px solid #fff;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  //
  font-size: 16px;
  font-weight: bold;

  @include mix.max-media(var.$screen-sm) {
    width: function.px2per(250, 310);
  }

  &:hover {
    background-color: #1ec755;
    color: #fff;
  }
}
