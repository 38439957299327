@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.img_fruid_unit {
  img {
    overflow: hidden;
    width: 100%;
  }
}
