@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

body{
  font-family: var.$font-family-base;
  color: var.$text-color;
  background: url(../img/common/main_bg.png) repeat;
}

h1,h2,h3,h4,h5{
  font-size: var.$font-size-heading;
  line-height: var.$line-height-heading;
  color: var.$headings-color;
}

ul,ol{
  font-family: var.$brand-font-serif;
  list-style: none;
}

a{
  width: 100%;
  text-decoration: none;
  @include mix.setTextLinkColor
}

p{
  font-family: var.$brand-font-serif;
  font-size: var.$font-size-base;
  line-height: var.$line-height-base;
  color: var.$text-color;
}

small{
  font-family: var.$brand-font-serif;
  font-size: 10px;
  color: #fff;
}

#app.js_isOffcanvasActive{
  right: 0;
  left: 0;
}

.heading01{
  font-family: var.$brand-font-serif;
  font-size: 24px;
  line-height: 1.224;
  @include mix.max-media(var.$screen-sm){
    font-size: 22px;
  }

  @include mix.max-media(var.$screen-mlg){
    font-size: 28px;
  }
}

.heading02{
  font-family: var.$brand-font-serif;
  font-size: 18px;

  border-bottom: 1px solid #000;
  padding-bottom: 15px;
  margin-bottom: 20px;

  @include mix.min-media(var.$screen-sm){
    font-size: 28px;
  }
}

.heading03{
  font-family: var.$brand-font-serif;
  font-size: 15px;
}