@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// トップページヒーロー部分

.top_section_hero {
  position: relative;
  width: 100%;
  z-index: 0;

  &:before {
    display: block;
    content: "";
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    //
    width: 100%;
    height: 17.587209%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(0, 0, 0, 1) 100%
    );
  }
}

.top_section_hero_title {
  z-index: 3;
  position: absolute;
  bottom: 7.848837%;
  left: 3.367496%;
  //
  font-size: 35px;
  line-height: 1.4;
  color: #fff;

  @include mix.max-media(var.$screen-sm) {
    font-size: 20px;
  }
}

.top_hero_img {
  width: 100%;

  img {
    width: 100%;
    max-width: 100%;
  }
}

// 下層ページヒーロー部分
// ============================================================
.menu_section_hero {
  position: relative;
  width: 100%;
}

.menu_hero_img {
  @include mix.min-media(var.$screen-sm) {
    max-height: 150px;
    overflow: hidden;
  }
  & img {
    width: 100%;
    height: auto;
  }
}

.menu_section_hero_title {
  z-index: 2;
  position: absolute;
  top: 30%;
  width: 100%;
  text-align: center;

  @include mix.min-media(var.$screen-md) {
    top: 40%;
  }

  //
  color: #fff;
}

// 
// ------------------------------------------------------------

#js_fvSlider{
  opacity: 0;
}

#js_fvSlider.is_init{
  opacity: 1;
}