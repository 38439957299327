@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
// 以下、Bootstrap Modalの構造スタイル上書きスタイル
// ============================================================

.modal {
  z-index: var.$zindex_modal;
}

.modal-backdrop.show {
  z-index: var.$zindex_modal_bg;
}

.modal.fade.show {
  background: var.$background_layer_color;
}

.modal-dialog {
  position: static;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  //
  width: 80%;
  min-height: 100vh;
  margin: 0 auto;
}

// ============================================================
// ここまで
// ============================================================

.modal_content {
  position: relative;
  padding: 15px;
}

// modalウィンドウに閉じるボタンを追加
// ============================================================

$closeUnit_color: #fff;
$closeUnitW: 31px;
$closeUnitH: 23px;

.modal_close {
  background: none;
  border: none;
}

// modalウィンドウに閉じるボタンを追加
// ============================================================

.modal_closeUnit {
  position: absolute;
  top: -10%;
  right: 0;
  width: $closeUnitW;
  height: $closeUnitH;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;

  display: flex;
  flex-flow: column;
  justify-self: center;
  align-items: center;
}

.modal_close span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $closeUnit_color;

  &:nth-of-type(1) {
    transform: translateY(-10px) rotate(-45deg);
  }

  &:nth-of-type(2) {
    transform: translateY(-10px) rotate(45deg);
  }
}

.modal_closeUnit_label {
  font-size: 10px;
  text-align: center;
  margin-top: 5px;
  font-family: var.$brand-font-serif;
  color: $closeUnit_color;
}

// modalウィンドウ下部にも閉じるボタンを追加
// ============================================================
.modal_close_btn {
  position: absolute;
  left: 17%;
  bottom: -4em;
  background-color: #eaeaea;
  max-width: 200px;

  @include mix.min-media(var.$screen-sm){
    left: 30%;
  }

  @include mix.min-media(var.$screen-md){
    left: 33%;
  }

  @include mix.min-media(var.$screen-slg){
    left: 36%;
  }

  @include mix.min-media(var.$screen-mlg){
    left: 40%;
  }

  p {
    font-weight: bold;
  }
}

// modal時はメニュー名と料金を横並びに

.modal .textUnit {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modal .heading03,
.modal .price {
  margin-bottom: 20px;
}

.modal .card01_img {
  margin-bottom: 20px;
}
