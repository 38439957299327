@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

@import "../../node_modules/animate.css/animate.min.css";

// 初期設定
// ------------------------------------------------------------

.js_animation_0{
  opacity: 0;
  &.js_animation_sequence{opacity: 1;}
}
.js_animation_25{
  opacity: 0;
  &.js_animation_sequence{opacity: 1;}
}
.js_animation_35{
  opacity: 0;
  &.js_animation_sequence{opacity: 1;}
}
.js_animation_50{
  opacity: 0;
  &.js_animation_sequence{opacity: 1;}
}
.js_animation_75{
  opacity: 0;
  &.js_animation_sequence{opacity: 1;}
}

.js_animation_sequence_target{
  opacity: 0;
  &.js_animation_sequence{opacity: 1;}
}

.js_loading_cb_animation{
  opacity: 0;
}

//
// ------------------------------------------------------------

.js_animation_fadeInDown.js_isActive{
  opacity: 1;
  animation: fadeInDown;
  animation-duration: 1s;
}

.js_animation_fadeOut{
  opacity: 1;
  animation: fadeOut;
  animation-duration: 1s;
}

.js_animation_sequence_target.js_isActive{
  opacity: 1;
  animation: fadeInDown;
  animation-duration: 1s;
}

.js_animation_fadeIn.js_isActive{
  opacity: 1;
  animation: fadeIn;
  animation-duration: 2s;
}

.js_animation_fadeInLeft.js_isActive{
  opacity: 1;
  animation:  fadeInLeft;
  animation-duration: 2s;
}

.js_animation_fadeInRight.js_isActive{
  opacity: 1;
  animation: fadeInRight;
  animation-duration: 2s;
}

.js_animation_fadeInUp.js_isActive{
  opacity: 1;
  animation: fadeInUp;
  animation-duration: 2s;
}