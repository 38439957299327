@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.menu_section_title {
  // stickyを一旦ストップ
  // position: sticky;
  // top: 0;
}

.price {
  color: #e00000;
  font-size: 13px;
}

.price_byList{
  color: #901E1E;
  font-size: 14px;
}

// recommend_menu
// ============================================================

.recommend_menu {
  background-color: #f4f4f4;
  padding: 25px 0 35px;
}

.recommend_menu_title {
  font-family: var.$brand-font-serif;
  font-size: 18px;
  text-align: center;

  margin-bottom: 25px;
}


.fz_sm{
  font-size: .8em;
}