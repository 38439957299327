@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.menu_link_index {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 40px;
}

// link_index_btn
// ============================================================
.link_index_btn {
  height: 100%;
}

// menu_lunch_index
// ============================================================

.menu_lunch_index {
  margin-bottom: 30px;
  border-bottom: 1px solid #707070;

  &:last-of-type {
    border-bottom: none;
  }
}

.lunch_index_card {
  margin-bottom: 20px;

  &:nth-of-type(n + 2) {
    margin-right: 10px;
  }
  &:nth-of-type(odd) {
    margin-right: 0;
    @include mix.min-media(var.$screen-sm) {
      margin-right: auto;
    }
  }

  &:first-of-type {
    width: 100%;
    margin-right: 0;
    @include mix.min-media(var.$screen-sm) {
      width: function.px2per(350, 710);
      margin-right: 10px;
    }

    .card01_textUnit {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }

  @include mix.min-media(var.$screen-sm) {
    width: function.px2per(170, 710);

    &:nth-of-type(3n + 8) {
      margin-right: 0;
    }
  }
}
