@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.card01_img {
  overflow: hidden;
  aspect-ratio: 16/9;
  margin-bottom: 10px;
}

.card01_img img {
  display: block;
  width: 100%;
  height: 100%;
}
