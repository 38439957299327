@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.list01{
  list-style: disc;
  margin-left: 20px;
  line-height: 1.4;
  margin-bottom: 40px;


  li{
    margin-bottom: 14px;
  }
}